import React from 'react';
import StyledComponents from './styles';
import _ from 'lodash';

const { Card, TitleBox, IconBox, TitleIcon, TitleText, Subtitle, Details, DetailsText, ViewQuoteButton } =
  StyledComponents;

export default function QuoteApprovedCard({ requestData, bidIndex, confirmedOnDate, translations, show }) {
  if (!show) return null;
  return (
    <React.Fragment>
      <Card>
        <React.Fragment>
          <TitleBox>
            <IconBox>
              <TitleIcon className='far fa-stamp' />
            </IconBox>
            <TitleText>{translations.title}</TitleText>
          </TitleBox>
          <Subtitle>
            {confirmedOnDate.formattedDate} {translations.dateTimeSeparator} {confirmedOnDate.formattedTime}
          </Subtitle>
          <Details>
            <DetailsText>{translations.description}</DetailsText>
            <ViewQuoteButton
              as='a'
              href={`${window.location.origin}/installation-quote?&token=${requestData.Bids[bidIndex].Id}`}
              target='_blank'
              disabled={requestData.Status == 'Canceled'}
            >
              {translations.button}
            </ViewQuoteButton>
          </Details>
        </React.Fragment>
      </Card>
    </React.Fragment>
  );
}

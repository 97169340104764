import React, { Fragment } from 'react'
import StyledComponents from './styles';

import { getBidToken } from '../../../helpers/BidsDates';

const {
	Card,
	TitleBox,
	IconBox,
	TitleIcon,
	TitleText,
	Details,
	DetailsText,
	ViewQuoteButton
} = StyledComponents;

export default function ApproveRevisedQuoteCard({ serviceProviderName, requestData, translations, show }) {


  const {
    title,
    descriptionOne,
    descriptionTwo,
    descriptionThree,
		button,
		punctuationPeriod
  } = translations;

  const id = getBidToken(requestData);

  if(!show) return null;
	return (
		<Fragment>
			<Card>
				<TitleBox>
					<IconBox>
						<TitleIcon className="far fa-stamp" />
					</IconBox>
					<TitleText>
						{title}
					</TitleText>
				</TitleBox>
				<Details>
					<DetailsText>
						{serviceProviderName} {descriptionOne} {descriptionTwo} {requestData.WorkerType}{punctuationPeriod}
						<br />
						<br />
						{descriptionThree} <b>{requestData.Email}</b>{punctuationPeriod}
					</DetailsText>
					<ViewQuoteButton as="a" href={`${window.location.origin}/installation-quote?&token=${id}`} target="_blank">
						{button}
					</ViewQuoteButton>
				</Details>
			</Card>
		</Fragment>
	)
}
